import { FormControl, FormHelperText, InputLabel, Select, Tooltip } from '@mui/material';
import { Field } from 'common/utils/validations';
import { isFunction } from 'lodash';
import { ChangeEvent, FC, ReactElement, ReactNode } from 'react';
import '../form_fields.scss';
import { CIFieldProps } from './fields.interface';

export interface CIDropdownProps extends CIFieldProps {
    showErrorIfModified?: boolean;
    tooltip?: string;
    formOnChangeOverride?: (event: ChangeEvent<HTMLSelectElement>, formOnChange: (value: unknown) => void) => void;
}

export const CIDropDown: FC<CIDropdownProps> = (props): ReactElement => {
    const {
        name,
        id,
        t,
        labelI18NKey,
        className = '',
        required,
        children,
        variant = 'standard',
        validations = [],
        fullWidth,
        disabled,
        margin,
        onChange,
        iconComponent,
        onOpen,
        onClose,
        menuProps,
        multiple,
        multiline = false,
        labelId,
        disableUnderline = false,
        displayEmpty = false,
        renderValue,
        showErrorOnLoad = false,
        showErrorIfModified = false,
        ariaLabel = null,
        readOnly = false,
        tooltip = null,
        helperText = null,
        formOnChangeOverride,
    } = props;

    // If `variant` is outlined then `disableUnderline` prop is not valid for Select Input
    const disableUnderlineProp = variant !== 'outlined' ? { disableUnderline } : {};

    return (
        <Field
            t={t}
            id={id}
            name={name}
            validations={validations}
            render={({ input, meta }): ReactNode => {
                const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
                    const formOnChange = input?.onChange;
                    if (!isFunction(formOnChangeOverride)) {
                        formOnChange(e.target.value);
                    } else {
                        formOnChangeOverride(e, formOnChange);
                    }
                    onChange && onChange(e); //Parent OnChange
                };

                let showError = showErrorOnLoad ? !!meta.error : meta.error && meta.touched;
                if (!showError && showErrorIfModified) {
                    showError = meta.error && meta.modified;
                }

                let select = (
                    <Select
                        multiple={multiple}
                        inputProps={{
                            name,
                            id,
                            'aria-label': ariaLabel,
                            readOnly: readOnly,
                        }}
                        fullWidth={fullWidth}
                        {...input}
                        onChange={handleChange}
                        IconComponent={iconComponent}
                        onOpen={onOpen}
                        onClose={onClose}
                        MenuProps={menuProps}
                        labelId={labelId}
                        multiline={multiline}
                        displayEmpty={displayEmpty}
                        renderValue={renderValue}
                        {...disableUnderlineProp}
                    >
                        {children}
                    </Select>
                );
                if (!!tooltip) {
                    select = <Tooltip title={tooltip}>{select}</Tooltip>;
                }

                return (
                    // CIDrop-Down class used to override some RTL issues
                    <div className={`CIDrop-Down ${!!input?.value?.length ? 'has-value' : ''}  ${className} `}>
                        <FormControl
                            error={showError}
                            variant={variant}
                            fullWidth={fullWidth}
                            margin={margin}
                            disabled={disabled}
                        >
                            <InputLabel htmlFor={id} required={required} variant={variant}>
                                {labelI18NKey && t(labelI18NKey)}
                            </InputLabel>
                            {select}
                            {showError ? <FormHelperText>{t(meta.error)}</FormHelperText> : <></>}
                            {helperText && <FormHelperText>{helperText}</FormHelperText>}
                        </FormControl>
                    </div>
                );
            }}
        />
    );
};
