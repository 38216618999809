import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { lazy } from 'react';
export let routes = [
    {
        component: lazy(() => import('module/econsentbuilder/builder/builder.page')),
        permissions: ['Customer_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/site/:versionPk/:versionSk/design',
    },
    {
        component: lazy(() => import('module/econsentbuilder/builder/builder.page')),
        permissions: ['Customer_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.Librarian,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/:studyId/:versionPk/design',
    },
    {
        component: lazy(() => import('module/econsentbuilder/translation/translation.page')),
        permissions: ['Customer_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.TranslationRepresentative,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/site/:versionPk/:versionSk/translate',
    },
    {
        component: lazy(() => import('module/econsentbuilder/translation/translation.page')),
        permissions: ['Customer_Read'],
        role: [
            BaseRoles.AdminId,
            BaseRoles.UserId,
            RoleVariantEnum.StudyBuilder,
            RoleVariantEnum.TranslationRepresentative,
            RoleVariantEnum.AdminReadOnly,
        ],
        path: '/:studyId/:versionPk/translate',
    },
];
