export enum RespondentType {
    Patient = 'PATIENT',
    Caregiver = 'CAREGIVER',
    Patient_Or_Caregiver = 'PATIENT_OR_CAREGIVER',
}

export enum UserTypes {
    Sponsor = 'Sponsor',
    Subscriber = 'Subscriber',
    Patient = 'Patient',
}

export enum S3FileTagEnum {
    ATTACHMENT = 'ATTACHMENT',
    BUTTON = 'BUTTON',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    LABEL = 'LABEL',
}

export interface S3File {
    name: string;
    description: string;
    s3Key?: string;
    file?: File;
    tags?: [S3FileTagEnum];
}

export enum AWSRegionEnum {
    APeast1 = 'ap-east-1',
    EUwest1 = 'eu-west-1',
    APsoutheast1 = 'ap-southeast-1',
    USeast1 = 'us-east-1',
}

export enum AWSRegionUATEnum {
    USwest1 = 'us-west-1',
}

export enum CustomerStatusEnum {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum CognitoErrorMessage {
    UserDisabled = 'User is disabled.',
}

export enum ModelSortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum BaseRoles {
    AdminId = 'RoleB.a7432a50-6898-4696-b123-b8e39b54651d',
    UserId = 'RoleB.2471ea3a-b8f4-48fd-8014-972752505a8c',
}

export enum RoleVariantEnum {
    StudyBuilder = 'RoleV.04e360ab-7734-4760-91bf-79d02e2aea69',
    TranslationRepresentative = 'RoleV.83106bbf-3cb2-493a-9c57-a8af1bec672c',
    Librarian = 'RoleV.0b169d01-2a47-4bb1-850e-371813e781f2',
    StudySupport = 'RoleV.15ccde35-1928-47c7-a7f8-5c7aa03cabf2',
    SiteInvestigator = 'RoleV.6420d1ae-32eb-4697-82ff-ce5a5be2881b',
    SiteCoordinator = 'RoleV.7ba8a29d-d9e5-4e59-b076-9dd3682b85ea',
    Monitor = 'RoleV.5b2343db-f7bb-4987-901b-2ef0222f046c',
    SystemAdminstrator = 'SystemAdministrator',
    ClinicalTrialLead = 'RoleV.32b25f36-1f8d-4219-9984-8ee77b3d0f4c',
    Inspector = 'RoleV.222b420f-814b-4705-8a8b-5b306abd3078',
    BlindedStudyManager = 'RoleV.1c576513-cde1-41fe-8655-340499b0ecbe',
    ProductSupport = 'RoleV.8082fd31-467b-4537-841f-b5e40373277b',
    DataChangeSupport = 'RoleV.f8314a4a-68ca-464a-8c10-49f7416f38b8',
    DataManager = 'RoleV.31c839a9-cef5-4c50-9999-a7c6bdaae2d7',
    AdminReadOnly = 'RoleV.f1d63622-c038-4b31-b5bc-2e03b451c3e5',
}

export enum UserType {
    Patient = 'Patient',
}

export enum MdsPlatformEnum {
    LUNEXIS = 'LUNEXIS',
    CAPTURE = 'CAPTURE',
    BRAIN_BASELINE = 'BRAIN_BASELINE',
}

export enum MdsPlatformShortCodeEnum {
    LUNEXIS = 'LUNEXIS',
    CAPTURE = 'CAPTURE',
    BRAIN_BASELINE = 'BRAIN_BASELINE',
}

export interface EosLog {
    jobName?: string;
    error?: string;
    cause?: string;
}

export const allRoles = [
    BaseRoles.AdminId,
    BaseRoles.UserId,
    RoleVariantEnum.TranslationRepresentative,
    RoleVariantEnum.StudySupport,
    RoleVariantEnum.StudyBuilder,
    RoleVariantEnum.SiteInvestigator,
    RoleVariantEnum.SiteCoordinator,
    RoleVariantEnum.Librarian,
    RoleVariantEnum.Monitor,
    RoleVariantEnum.DataManager,
    RoleVariantEnum.ClinicalTrialLead,
    RoleVariantEnum.Inspector,
    RoleVariantEnum.BlindedStudyManager,
    RoleVariantEnum.ProductSupport,
    RoleVariantEnum.AdminReadOnly,
];

export enum RoleTypeEnum {
    StudyBuilder = 'studybuilder',
    Librarian = 'librarian',
    SiteInvestigator = 'siteinvestigator',
    SiteCoordinator = 'sitecoordinator',
}

export enum UserSubType {
    SITE = 'SITE',
    MONITOR = 'MONITOR',
    INSPECTOR = 'INSPECTOR',
    CAREGIVER = 'CAREGIVER',
    SPONSOR = 'SPONSOR',
    PRODUCT_SUPPORT = 'PRODUCT_SUPPORT',
    DATA_CHANGE_SUPPORT = 'DATA_CHANGE_SUPPORT',
    DATA_MANAGER = 'DATA_MANAGER',
}

export enum AuthUserType {
    PATIENT = 'Patient',
}

export enum RoleCategoryEnum {
    System = 'System',
    Subscriber = 'Subscriber',
    Sponsor = 'Sponsor',
    Site = 'Site',
    Subject = 'Subject',
}

export enum RoleStatusEnum {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum SiteStatusEnum {
    Inactive = 'Inactive',
    Active = 'Active',
}

export enum StudySiteStatusEnum {
    Inactive = 'Inactive',
    Active = 'Active',
    Locked = 'Locked',
    Unlocked = 'Unlocked',
    EnrollmentComplete = 'EnrollmentComplete',
    Dropped = 'Dropped',
}

export enum SiteReviewStatusEnum {
    InReview = 'INREVIEW',
    Live = 'LIVE',
    Build = 'BUILD',
}

export enum StudyTypeEnum {
    EPRO = 'EPRO',
    ECOA = 'ECOA',
    ESOURCE = 'ESOURCE',
}

export enum StudyPhaseEnum {
    PhaseI = 'PhaseI',
    PhaseIIA = 'PhaseIIA',
    PhaseIIB = 'PhaseIIB',
    PhaseIII = 'PhaseIII',
    PhaseIIIB = 'PhaseIIIB',
    PhaseIV = 'PhaseIV',
    Other = 'Other',
}

export enum StudyStatusEnum {
    Inactive = 'Inactive',
    Active = 'Active',
    Archived = 'Archived',
    Closed = 'Closed',
    DataLock = 'DataLock',
    Locked = 'Locked',
    Unlocked = 'Unlocked',
    Deleted = 'Deleted',
}

export enum SubscriberStatusEnum {
    Pending = 'Pending',
    Active = 'Active',
    Inactive = 'Inactive',
}
export enum SponsorStatusEnum {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum UserStatusEnum {
    Active = 'Active',
    Inactive = 'Inactive',
}

export enum TaskStatusEnum {
    NOTTRIGGERED = 'NOTTRIGGERED',
    NOTSTARTED = 'NOTSTARTED',
    INPROGRESS = 'INPROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    CONDEXPIRED = 'CONDEXPIRED',
    RECALLED = 'RECALLED',
}

export enum TaskResultStatusEnum {
    NOTSTARTED = 'NOTSTARTED',
    INPROGRESS = 'INPROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    CONDEXPIRED = 'CONDEXPIRED',
    RECALLED = 'RECALLED',
}

export enum TaskTypeEnum {
    TIMED = 'TIMED',
    SCHEDULED = 'SCHEDULED',
    EPISODIC = 'EPISODIC',
}

export enum ActivityUserRolesEnum {
    CAREGIVER = 'Caregiver',
    PATIENT = 'Patient',
    BOTH = 'Both',
}

export enum UserIdentityProviderEnum {
    Cognito = 'Cognito',
    Okta = 'Okta',
}

export enum StudySiteEosRecordStatus {
    NOT_ENABLED = 'NOT_ENABLED',
    ENABLED = 'ENABLED',
}

export enum EosRunStatus {
    NOT_ENABLED = 'NOT_ENABLED',
    NOT_STARTED = 'NOT_STARTED',
    QUEUED = 'QUEUED',
    INPROGRESS = 'INPROGRESS',
    FINISHED = 'FINISHED',
    FINISHED_W_ERROR = 'FINISHED_W_ERROR',
    ERRORED = 'ERRORED',
    CANCELLED = 'CANCELLED',
}

export enum EosDownloadStatus {
    NOT_ENABLED = 'NOT_ENABLED',
    ENABLED = 'ENABLED',
    REQUESTED = 'REQUESTED',
    ERRORED = 'ERRORED',
    SUCCEEDED = 'SUCCEEDED',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    AVAILABLE = 'AVAILABLE',
}

export interface ModelConnection<T> {
    items: [T];
    totalItems: number;
    nextToken: string;
}

export interface LocalTime {
    timestamp: Date | string;
    timezone: string;
}

export interface UserName {
    firstName?: string;
    lastName?: string;
    username?: string;
    caregiverRelationshipId?: string;
    caregiverRelationDefName?: string;
    caregiverNumber?: number;
}

export interface BaseSchema {
    pk?: string;
    sk?: string;
    data?: string;
    data2?: string;
    isDeleted?: number;
    version?: number;
    tn?: string;
    schema?: string;
    createdAt?: string | Date;
    createdAtLocal?: LocalTime;
    createdBy?: string;
    createdByDevice?: string;
    updatedAt?: string | Date;
    updatedAtLocal?: LocalTime;
    updatedBy?: string;
    updatedByDevice?: string;
}

export interface ListResponse<T> {
    items: T[];
    totalItems: number;
}

export interface CreatePerson {
    pk?: string;
    name: string;
    startDate: Date | string;
    type?: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    userPassword?: string;
    roleId: string;
    phone: string;
    category?: string;
    activationCode?: string;
    accActivationStatus?: string;
    preferredTimezone?: string;
    mdsPlatforms?: MdsPlatformEnum[];
    selectedPlatformOptions?: MdsPlatformOption[];
    sponsors?: {
        data: string;
        name: string;
        pk: string;
        sk: string;
    };
    userStudies?: UserStudies[];
    guidesStatus?: UserGuidesStatus[];
}

export function prefillBaseSchema<T extends BaseSchema>(obj: T): T {
    obj.createdAt = new Date();
    obj.updatedAt = new Date();
    obj.version = 1;
    obj.isDeleted = 0;
    obj.schema = '1.0';
    obj.data = null;

    return obj;
}

export type RoleB = {
    // Base Role
    name: string;
    description: string;
    category: RoleCategoryEnum;
    status: RoleStatusEnum;
    rbPerms: ModelConnection<RBPerm>;
} & BaseSchema;

export type RBPerm = {
    name: string;
} & BaseSchema;

export type RoleV = {
    name: string;
    description: string;
    category: RoleCategoryEnum;
    status: RoleStatusEnum;
    roleB: RoleB;
    rvPerms: ModelConnection<RVPerm>;
} & BaseSchema;

export type RVPerm = {
    name: string;
} & BaseSchema;

export type StudyArm = {
    pk?: string;
    sk?: string;
    armId?: string;
    name?: string;
};

export type Study = {
    name?: string;
    type?: StudyTypeEnum;
    status?: StudyStatusEnum;
    mdsPlatforms: MdsPlatformEnum[];
    protocolId?: string;
    sponsorId?: string;
    customerId?: string;
    displayName?: string;
    therapeuticArea?: string;
    indication?: string;
    description?: string;
    startDate?: Date | string;
    goLiveDate?: Date | string;
    drugName?: string;
    conditionName?: string;
    customerName?: string;
    sponsorName?: string;
    languages?: string[] | { locale: string }[];
    versionStr?: string;
    versionStatus?: StudyVersionStatusEnum;
    arms?: StudyArm[];
    numberOfActiveSites?: number;
    hasData?: boolean;
    salesforceId?: string;
    ciProjectCode?: string;
    phase?: string;
    studyModality?: StudyModalityEnum[];
    selectedPlatformOptions?: MdsPlatformOption[];
    pk?: `Study.${string}`;
    sites?: StudySite[];
    expectedVersion?: number;
    numberOfActivePatients?: number;
    hasPatientIdRange?: boolean;
    preconsentIdAlias?: string;
    screeningIdAlias?: string;
    prescreeningIdAlias?: string;
    randomizationIdAlias?: string;
    preconsentIdFormat?: string;
    screeningIdFormat?: string;
    prescreeningIdFormat?: string;
    randomizationIdFormat?: string;
} & BaseSchema;

export enum StudyVersionStatusEnum {
    Build = 'BUILD',
    InReview = 'INREVIEW',
    Live = 'LIVE',
    ArchivedPostLive = 'ARCHIVED_POST_LIVE',
    ArchivedPostReview = 'ARCHIVED_POST_REVIEW',
    Publishing = 'PUBLISHING_IN_PROCESS',
    PublishFail = 'PUBLISH_FAIL',
}

export type Subscriber = {
    name: string;
    startDate: Date;
    status: SubscriberStatusEnum;
    numberOfActiveCustomers: number;
    userPasswordExpiresInDays: number;
    userPasswordExpirationEmailNotifyDaysBefore: number[];
    userPasswordExpirationInAppNotifyDaysBefore: number;
    allowDcf?: boolean;
    allowCiOkta?: boolean;
} & BaseSchema;

export type Sponsor = {
    name: string;
    status: SponsorStatusEnum;
    salesforceId: string;
    webUrl: string;
    startDate: Date | string;
    hasData?: boolean;
    createdByName?: UserName;
} & BaseSchema;

export type PatientIdRange = {
    studyId: string;
    fieldName: string;
    minValue: number;
    maxValue: number;
    numIdsRemaining: number;
    numIdsRemainingBuild?: number;
    numIdsRemainingUat?: number;
} & BaseSchema;

export type PatientIdRangeAvailability = {
    patientId: string;
    siteId: string;
    rangeValue: number;
    available: boolean;
    fieldName: string;
    fieldIdentifier: string;
    filledFieldIdentifier: string;
    fieldIdentifierFormat: string;
    siteName?: string;
    siteIdentifier?: string;
} & BaseSchema;

export type CreateCustomerInput = {
    subscriberId: string;
} & CreatePerson;

export type Customer = {
    name: string;
    startDate: number | string | Date;
    status: CustomerStatusEnum;
    studies: ModelConnection<Study>;
    numberOfActiveStudies: number;
    hasData?: boolean;
    subscriberId: string;
} & BaseSchema;

export type Site = {
    name?: string;
    siteIdentifier?: string;
    displayName?: string;
    salesforceId?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
    timezone?: string;
    timeZoneId?: string;
    awsRegion?: AWSRegionEnum;
    status?: SiteStatusEnum;
    reviewStatus?: SiteReviewStatusEnum;
    mdsPlatforms?: MdsPlatformEnum[];
    selectedPlatformOptions?: MdsPlatformOption[];
} & BaseSchema;

export type AWSRegions = {
    awsRegionName: string;
    name: string;
    pk: string;
    sk: string;
    status?: string;
    isUatRegion: boolean;
};

export interface UserLastPage {
    url?: string;
    studyId?: string;
    siteId?: string;
}

export interface PublicKey {
    deviceId: string;
    key: string;
}

export type MdsPlatformOption = {
    name: string;
    value: MdsPlatformEnum;
};

export const platformOptions: MdsPlatformOption[] = [
    {
        name: 'CX',
        value: MdsPlatformEnum.CAPTURE,
    },
    {
        name: 'LX',
        value: MdsPlatformEnum.LUNEXIS,
    },
    {
        name: 'BB',
        value: MdsPlatformEnum.BRAIN_BASELINE,
    },
];

export type UserGuidesStatus = {
    pk: string;
    sk: string;
    data: string;
    guideName: string;
    stepId: string;
    reason: string;
    isCompleted: boolean;
    updatedAt: string;
    completedAt?: string;
    version: number;
};

export type User = {
    name: string;
    firstDay?: string;
    firstName: string;
    lastName: string;
    email: string;
    parentId: string;
    status: UserStatusEnum;
    phone: string;
    roleName: string;
    createdAt?: Date;
    createdByName: UserName;
    updatedByName: UserName;
    activationDate: string;
    lastActiveDate: string;
    sponsorId?: string;
    type: string;
    acceptedVersionPP: number;
    category?: string;
    sponsorName?: string;
    roleId?: string;
    subType?: UserSubType;
    preferredTimezone?: string;
    guidesStatus?: UserGuidesStatus[];
    roleBClaim?: {
        data: string;
        sk: string;
    };
    roleVClaim?: {
        name: string;
        sk: string;
    };
    userStudies?: UserStudies[];
    userStudiesRaw?: UserStudies[];
    securityQuestion1Text?: string;
    securityQuestion2Text?: string;
    securityQuestion3Text?: string;
    dateOfLastPasswordChange?: Date;
    lastPagesOpened?: UserLastPage[];
    publicKeys: PublicKey[];
    identityProvider?: IdentityProvider;
    identityProviderartner?: IdentityProviderPartner;
    identityProviderPrefix?: string;
    mdsPlatforms: MdsPlatformEnum[];
    selectedPlatformOptions?: MdsPlatformOption[];
} & BaseSchema;

export type Patient = {
    name?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    type?: string;
    subType?: UserSubType;
    firstDay?: string;
    initials?: string;
    version?: number;
    isDeleted?: number;
    securityQuestion1Text?: string;
    securityQuestion2Text?: string;
    securityQuestion3Text?: string;
    acceptedVersionPP?: number;
    screeningIdentifier?: string;
    randomizationIdentifier?: string;
    prescreeningIdentifier?: string;
    createdAt?: Date | string;
    parentId?: string;
    armId?: string;
    studyInstanceId?: string;
    studyId?: string;
    siteId?: string;
    userStatus?: UserStatusEnum;
    status?: string;
    statusAlias?: StudyDefaultPatientStatusEnum | '-';
    preferredLanguage?: string;
    preferredTimezone?: string;
    activationDate?: string | Date;
    activationCode?: string;
    caregivers?: Caregiver[];
    eosSiteDownloadStatus?: EosDownloadStatus;
    eosSponsorDownloadStatus?: EosDownloadStatus;
    eosSiteFileSize?: number;
    eosSponsorFileSize?: number;
    eosSiteDownloadFileSize?: number;
    eosSponsorDownloadFileSize?: number;
    eosRanAt?: string;
    eosStatus?: EosRunStatus;
    eosPk?: string;
    eosSk?: string;
    eosLogs?: EosLog[];
    deviceId?: string;
    hasActivites?: {
        hasSiteActivities?: boolean;
        hasHomeActivities?: boolean;
    };
    patientId?: string; // @todo: Remove this once refactor is completed and not needed for PatientInfo
} & BaseSchema;

export interface Caregiver {
    pk?: string;
    caregiverNumber?: number;
    caregiverRelationshipId?: string;
    activationCode?: string;
    patientICareForId?: string;
    name?: string;
    isLAR?: boolean;
    isPrimaryCaregiver?: boolean;
}

export type RoleBClaim = {
    roleB: RoleB;
} & BaseSchema;

export type RoleVClaim = {
    roleV: RoleV;
} & BaseSchema;

export type UserStudyPermission = {
    user: User;
    study: Study;
    permission: string;
    description: string;
} & BaseSchema;

export type UserStudyRole = {
    user: User;
    study: Study;
    baseRoles: ModelConnection<RoleB>;
    roleVariants: ModelConnection<RoleV>;
} & BaseSchema;

export type UserAppPerm = {
    name: string;
    user: User;
} & BaseSchema;

export type PermissionClaim = {
    name: string;
} & BaseSchema;

export type UserStudy = {
    name: string;
    permissionClaim: ModelConnection<PermissionClaim>;
    roleBClaim: ModelConnection<RoleBClaim>;
    roleVClaim: ModelConnection<RoleVClaim>;
} & BaseSchema;

export interface GetUserQuery {
    UserByName: {
        items: User[];
    };
}

export interface GetListUsersAdjListQuery {
    listUsersAdjList: {
        items: (User | PermissionClaim | RoleBClaim | RoleVClaim | UserStudy)[];
    };
}

export interface GetUserPermissionsAndStudySitesQuery {
    getUser: User;
    getPermissionsByUser: {
        items: string[];
    };
}

export interface GetPatientDataQuery {
    getPatient: Patient;
    getPermissionsByUser: {
        items: string[];
    };
    getUser: User;
}
export interface AddSubscriberMutation {
    createSubscriber: Subscriber;
}

export interface EditSubscriberMutation {
    editSubscriber: Subscriber;
}

export interface AddCustomerMutation {
    addCustomer: Customer;
}

export interface CreateStudyInput {
    name: string;
    displayName: string;
    protocolId: string;
    salesforceId: string;
    projectCode: string;
    customerId: string;
    sponsorId: string;
    startDate: Date;
    goLiveDate: Date;
    therapeuticArea: string;
    indication: string;
    phase: StudyPhaseEnum;
    description: string;
    type: StudyTypeEnum;
    languages: string[];
    studyModality: StudyModalityEnum[];
    mdsPlatforms: MdsPlatformEnum[];
}
export interface Languages {
    locale: string;
    pk: string;
    sk: string;
    language?: string;
    status?: string;
    version?: number;
}
export interface VersionStatus {
    versionStatus: string;
}
export interface PaginationQuery {
    filterString?: string;
    prefilter?: object;
    filter: object;
    skipCount: number;
    limit: number;
    sort: PaginationSorting[];
}

export interface PaginationSorting {
    field: string;
    direction: string;
}

export interface FileUploadInput {
    fileName: string;
    contentType: string;
}

export interface S3Url {
    key: string;
    url: string;
}

export interface SearchResult<T> {
    items: T[];
    totalItems: number;
}

export interface ListStudySites {
    items?: StudySite[];
    totalItems?: number;
}

export interface Contact {
    type: ContactNumberEnum;
    number: string;
}

export interface StudySite extends Omit<Site, 'status'> {
    pk: string;
    sk: string;
    studyId?: string;
    siteId?: string;
    name?: string;
    siteName?: string;
    siteIdentifier?: string;
    displayName?: string;
    contacts?: Contact[];
    status?: StudySiteStatusEnum;
    primaryInvestigator?: string;
    primaryInvestigatorName?: UserName;
    languages?: string[] | Languages[];
    website?: string;
    eosSiteRecordStatus?: StudySiteEosRecordStatus;
    eosSponsorRecordStatus?: StudySiteEosRecordStatus;
    eosRunStatus?: EosRunStatus;
    eosSiteDownloadStatus?: EosDownloadStatus;
    eosSponsorDownloadStatus?: EosDownloadStatus;
    totalActivePatients?: number;
    hasAnyEosGenerationErrors?: boolean;
    hasAnyEosSiteDownloadErrors?: boolean;
    hasAnyEosSponsorDownloadErrors?: boolean;
    totalPatients?: number;
}

export interface UserStudies {
    pk?: string;
    sk?: string;
    tn?: string;
    data?: string;
    studyName?: string;
    userId?: string;
    studyId: string;
    version?: number;
    name?: string;
    userIdName?: {
        firstName: string;
        lastName: string;
        username: string;
    };
    userStudySites?: UserStudySite[];
    userStudySitesRaw?: UserStudySite[];
}

export interface UserStudySite {
    pk?: string;
    sk?: string;
    tn?: string;
    data?: string;
    siteName?: string;
    userId?: string;
    studyId?: string;
    siteId: string;
    version?: number;
    name?: string;
    siteIdentifier?: string;
}

export type StudyActivityTypeEnum = 'QUESTIONNAIRE';
export type ActivityTypeEnum = 'EPRO' | 'CLINRO';

export interface DbStudyActivty extends BaseSchema {
    pk: string;
    sk: string;
    tn: string;
    data: string;
    armId: string;
    activityGroupId: string;
    activityId: string;
    title: string;
    name: string;
    type: StudyActivityTypeEnum;
    activityType: ActivityTypeEnum;
    order: number;
    orderVersion: number;
    s3Key: string;
    checksum: string;
    copiedFrom: string;
    isLocked: boolean;
    activityRefVersion: number;
}

export enum StudyTaskDefinitionTypeEnum {
    TIMED = 'TIMED',
    SCHEDULED = 'SCHEDULED',
    EPISODIC = 'EPISODIC',
}

export interface StudyTaskDefinition extends BaseSchema {
    armId: string;
    taskDefId: string;
    name: string;
    type: StudyTaskDefinitionTypeEnum;
    anchors: StudyTaskDefinitionAnchor[];
    startTime: string;
    duration: number;
    tasksArr: StudyTaskDefinitionTask[];
    notificationsArr: StudyTaskDefinitionNotification[];
    actions: StudyTaskDefinitionAction[];
}

export type StudyTaskDefinitionAnchorTypeEnum = 'EVENT' | 'EVENT_GROUP' | 'TASK_EVENT_GROUP' | 'TASK';

export interface StudyTaskDefinitionAnchor {
    anchorFromId: string;
    anchorFromType: StudyTaskDefinitionAnchorTypeEnum;
    minOffset: number;
    dayOffset: number;
    recurrence: number;
    endAfterXOccurrences: number;
    endBeforeNextVisit: boolean;
    endBeforeNextSpecificVisit: string;
}

export interface StudyTaskDefinitionTask {
    systemName: string;
    taskTemplateId: string;
    title: string;
    description: string;
    icon: string;
    startAt: string;
    offset: number;
    endAfter: number;
    showBeforeStart: boolean;
    allowEarlyCompletion: boolean;
    allowLateCompletion: boolean;
    allowLateEdits: boolean;
}

export type StudyTaskDefinitionNotificationTypeEnum = 'PUSH';
export type StudyTaskDefinitionBeforeAfterEnum = 'BEFORE' | 'AFTER';

export interface StudyTaskDefinitionNotification {
    type: StudyTaskDefinitionNotificationTypeEnum;
    startMinutes: number;
    startWhen: StudyTaskDefinitionBeforeAfterEnum;
    allowStartAfterCompletion: boolean;
    messageTemplateId: string;
}

export type StudyTaskDefinitionActionTypeEnum = 'ACTIVITY' | 'CONTENT';

export interface StudyTaskDefinitionAction {
    entityId: string;
    entityType: StudyTaskDefinitionActionTypeEnum;
    entityName: string;
}

//-----------------------------------------------------
//  SUBJECT STUDY TASK RESULT

export interface TaskResultTaskDefinitionAction {
    entityId: string;
    entityType: string;
    entityName: string;
}

export interface TasResultTaskDefinition {
    pk: string;
    sk: string;
    name: string;
    actions: TaskResultTaskDefinitionAction[];
}

export interface Task {
    pk?: string;
    sk?: string;
    title: string;
    taskType: StudyTaskDefinitionTypeEnum;
    status: TaskStatusEnum;
    respondentType?: RespondentType;
    description: string;
    taskTemplateId: string;
    taskDefinitionId: string;
    isTranscribable?: TranscribableEnum;
    actions: {
        entityName: string;
        entityId: string;
        entityType: string;
        ref: {
            armId: string;
            activityGroupId: string;
            activityId: string;
            eventTime: string;
            eventDayOffset: number;
            eventId: string;
            eventGroupId: string;
        };
    }[];
    hasDocumentation?: boolean;
    canRecall?: number;
}

export interface TaskResult {
    pk?: string;
    sk?: string;
    title?: string;
    description?: string;
    studyId?: string;
    status?: TaskResultStatusEnum;
    dueDate?: string;
    taskDefinition?: TasResultTaskDefinition;
    taskInstanceId?: string;
    startedAt?: Date;
    updatedAt?: Date;
    completedAt?: Date;
}

export interface TaskInstanceTaskResult extends BaseSchema {
    pk: string;
    sk: string;
    studyId?: string;
    taskResultTitle: string;
    description: string;
    firstAvailableDate: Date;
    dueDate: string;
    dueAt: string;
    startAt: Date;
    startedAt: Date;
    completedAt: Date;
    completedAtLocal?: LocalTime;
    completedBy: string;
    startedBy: string;
    usersWithTempActivities?: TempActivityInstance[];
    respondentType: RespondentType;
    taskResultStatus: TaskResultStatusEnum;
    taskId: string;
    taskInstanceId: string;
    parentTaskInstanceId: string;
    taskDefinition: StudyTaskDefinition;
    taskCardInfoStatus?: TaskStatusEnum;
    TC: Task;
    noEndTime?: boolean;
    endDayOffset?: number;
    dayOffset?: number;
    startTime?: string;
    isTranscribed?: boolean;
    eventTime?: string;
    hasDocumentation?: boolean;
    status?: TaskStatusEnum;
}

export interface ListTaskResult {
    items: TaskInstanceTaskResult[];
    totalItems: number;
}

export interface TempActivityInstance {
    userId: string;
    userIdName: {
        caregiverNumber: number;
    };
}

export interface TaskMetric {
    pk: string;
    sk: string;
    totalTasks: number;
    totalTasksCompleted: number;
    dateUpdated: string;
}

//-----------------------------------------------------
//  SUBJECT STUDY INSTANCE ACTIVITY

export enum SubjectStudyInstanceStatusEnum {
    notStarted = 'NotStarted',
    inProgress = 'InProgress',
    incomplete = 'Incomplete',
    completed = 'Completed',
}

export enum DataTypeEnum {
    string = 'STRING',
    int = 'INT',
    float = 'FLOAT',
    boolean = 'BOOLEAN',
    dateTime = 'DATETIME',
    date = 'DATE',
    time = 'TIME',
}

export enum UnitsOfMeasureEnum {
    lengthCm = 'LENGTH_CM',
    lengthIn = 'LENGTH_INCH',
    lengthFt = 'LENGTH_FEET',
    lengthYd = 'LENGTH_YARD',
    pressureMMHg = 'PRESSURE_MMHG',
    pressureKPA = 'PRESSURE_KPA',
    pulseBPM = 'PULSE_BPM',
    respirationBPM = 'RESPIRATION_BPM',
    tempF = 'TEMPERATURE_FAHRENHEIT',
    tempC = 'TEMPERATURE_CELSIUS',
    volumeML = 'VOLUME_ML',
    volumeL = 'VOLUME_L',
    volumeDrops = 'VOLUME_DROPS',
    volumenOZ = 'VOLUME_OZ',
    volumeCC = 'VOLUME_CC',
    volumeTsp = 'VOLUME_TSP',
    volumeTbsp = 'VOLUME_TBS',
    volumeCup = 'VOLUME_CUP',
    volumeQT = 'VOLUME_QUART',
    volumePT = 'VOLUME_PINT',
    weightKG = 'WEIGHT_KG',
    weightG = 'WEIGHT_G',
    weightMG = 'WEIGHT_MG',
    weightMCG = 'WEIGHT_MCG',
    weightOz = 'WEIGHT_OZ',
    weightLb = 'WEIGHT_LB',
}

export enum UnitsOfMeasureFEEnum {
    lengthCm = 'LENGTH_CM',
    lengthIn = 'LENGTH_INCH',
    lengthFt = 'LENGTH_FEET',
    lengthFtIn = 'LENGTH_FEET_AND_INCH',
    lengthMCm = 'LENGTH_METER_AND_CENTIMETER',
    lengthYd = 'LENGTH_YARD',
    pressureMMHg = 'PRESSURE_MMHG',
    pressureKPA = 'PRESSURE_KPA',
    pulseBPM = 'PULSE_BPM',
    respirationBPM = 'RESPIRATION_BPM',
    tempF = 'TEMPERATURE_FAHRENHEIT',
    tempC = 'TEMPERATURE_CELSIUS',
    volumeML = 'VOLUME_ML',
    volumeL = 'VOLUME_L',
    volumeDrops = 'VOLUME_DROPS',
    volumenOZ = 'VOLUME_OZ',
    volumeCC = 'VOLUME_CC',
    volumeTsp = 'VOLUME_TSP',
    volumeTbsp = 'VOLUME_TBS',
    volumeCup = 'VOLUME_CUP',
    volumeQT = 'VOLUME_QUART',
    volumePT = 'VOLUME_PINT',
    weightKG = 'WEIGHT_KG',
    weightG = 'WEIGHT_G',
    weightMG = 'WEIGHT_MG',
    weightMCG = 'WEIGHT_MCG',
    weightOz = 'WEIGHT_OZ',
    weightLb = 'WEIGHT_LB',
}

export interface StudyAnswer {
    id: string;
    type: DataTypeEnum;
    text?: string;
    enText?: string;
    unitOfMeasureBE: UnitsOfMeasureEnum;
    unitOfMeasureFE: UnitsOfMeasureFEEnum;
    value: string;
    valueString?: string;
    valueInt?: number;
    valueFloat?: number;
    valueBoolean?: boolean;
    valueDateTime?: string;
    valueDate?: string;
    valueTime?: string;
    valueCoding?: number | null;
    codedSelection?: number | null;
    unknownMonth?: boolean;
    unknownDay?: boolean;
}

export interface CalculationData {
    key?: string;
    armId?: string;
    eventGroupId?: string;
    eventId?: string;
    eventDayOffset?: number;
    eventTime?: string;
    activityGroupId?: string;
    activityId?: string;
    questionId?: string;
    value?: string;
    valueString?: string;
    hashKey?: string;
}

//-----------------------------------------------------
//  STUDY RULE
export enum JSONConditionTypeEnum {
    ALL = 'all',
    ANY = 'any',
}

export type StudyRuleParentTypeEnum = 'Activity' | 'TaskDef';

export interface SiteAlertRule {
    key: string;
    url: string;
}

export interface StudyRule {
    pk: string;
    sk: string;
    data: string;
    version: number;
    parentEntityId: string;
    parentEntityType: StudyRuleParentTypeEnum;
    minStudyVersion: string;
    maxStudyVersion: string;
    includeStudyVersions: string[];
    excludeStudyVersions: string[];
    name: string;
    isServerRule: boolean;
    conditions: JSONCondition;
    event: StudyRuleEvent;
    versions: number;
    isDeleted: 0 | 1;
    createdAt: Date;
    createAtLocal: LocalTime;
    createdBy: string;
    createdByDevice: string;
    updatedAt: Date;
    updatedAtLoca: LocalTime;
    updatedBy: string;
    updatedByDevice: string;
    createdByName: UserName;
    updatedByName: UserName;
}

export interface CreateStudyRule {
    name: string;

    parentEntityId: string;
    parentEntityType: string;

    minStudyVersion: string;

    isServerRule: boolean;

    conditions: InputJSONConditionBaseLevel;
    event: StudyRuleEvent;
}

export interface UpdateStudyRule {
    pk: string;
    sk: string;
    isDeleted: 0 | 1;
    expectedVersion: number;

    name: string;

    parentEntityId: string;
    parentEntityType: string;

    minStudyVersion: string;
    maxStudyVersion: string;

    isServerRule: boolean;

    conditions: InputJSONConditionBaseLevel;
    event: StudyRuleEvent;
}

export interface InputJSONConditionBaseLevel {
    all?: JSONCondition[];
    any?: JSONCondition[];
}

export interface JSONCondition {
    fact?: string;
    path?: string;
    operator?: JSONConditionOperatorEnum;
    value?: string;
    all?: JSONCondition[];
    any?: JSONCondition[];
}

export interface StudyRuleEvent {
    id?: string;
    type: StudyRuleEventTypeEnum;
    params?: UpdateStudyActivityObject[];
    notificationParams: StudyRuleNotificationParams;
}

export interface UpdateStudyActivityObject {
    key: string;
    value: string;
}

export interface StudyRuleNotificationParams {
    notificationTemplateId?: string;
    sites: StudyRuleNotificationDeliveryParams[];
    global: StudyRuleNotificationDeliveryParams;
    extraNotificationText?: string;
}

export interface StudyRuleNotificationDeliveryParams {
    siteId?: string;
    deliver: StudyRuleDeliverySchedule;
    recipients: StudyRuleNotificationRecipient[];
}

export interface StudyRuleDeliverySchedule {
    cron: string;
    timeZoneId?: string;
}

export interface StudyRuleNotificationRecipient {
    userId: string;
}

export enum JSONConditionOperatorEnum {
    EQUAL = 'EQUAL',
    NOTEQUAL = 'NOTEQUAL',
    LT = 'LT',
    GT = 'GT',
    LTE = 'LTE',
    GTE = 'GTE',
    ID_IN_ARRAY = 'ID_IN_ARRAY',
    ID_NOT_IN_ARRAY = 'ID_NOT_IN_ARRAY',
}

export type StudyRuleEventTypeEnum = 'NOTIFICATION';

export enum ContactNumberEnum {
    PHONE = 'PHONE',
    TWENTYFOURHOUR = 'TWENTYFOURHOUR',
    FAX = 'FAX',
    OTHER = 'OTHER',
    HELPDESK = 'HELPDESK',
}

//============================================
// TranslatedReason

export type TranslatedReasonTargetEnum =
    | 'DeleteUnscheduledVisit'
    | 'DCFReason'
    | 'ActivityIncomplete'
    | 'ActivityTranscription'
    | 'DataChangeRFC'
    | 'PatientRFC'
    | 'CaregiverRelationship'
    | 'DocumentationReason'
    | 'OverrideReason';

export interface TranslatedReason {
    pk?: string;
    sk?: string;
    title: string;
    templateId?: string;
    target: TranslatedReasonTargetEnum;
    language: string;
    isOther: boolean;
    isDefault: boolean;
    isDeactivated: boolean;
    version: number;
    isDeleted: 0 | 1;
    isDefaultSelected?: boolean;
}

export interface UpsertTranslatedReason {
    pk?: string;
    sk?: string;
    parentId?: string;
    title?: string;
    target?: TranslatedReasonTargetEnum;
    language?: string;
    isOther?: boolean;
    isDeactivated?: boolean;
    isDefaultSelected?: boolean;
    version?: number;
    isDeleted?: 0 | 1;
}

//============================================
// ReportMetaDataToken

export interface ReportMetaData {
    id: string;
    name: string;
    description: string;
}

export interface ReportMetaDataToken extends ReportMetaData {
    accessToken: string;
    tokenExpiration: string;
    reviewStatus: SiteReviewStatusEnum;
}

//============================================
// DataClarificationForm

export interface DataClarificationForm {
    pk: string;
    sk: string;
    tn: string;
    data: string;
    data2: string;
    patientId: string;
    studyInstanceId: string;
    studyId: string;
    siteId: string;
    hashKey: string;
    hashKeyEvent: string;
    armId: string;
    eventGroupId: string;
    eventId: string;
    activityGroupId: string;
    activityId: string;
    eventDayOffset: number;
    eventTime: string;
    reasonId: string;
    reasonOther: string;
    description: string;
    salesForceCaseNumber: string;
    schema: string;
    version: number;
    isDeleted: number;
    createdAt: string;
    createdAtLocal: LocalTime;
    createdBy: string;
    createdByDevice: string;
    updatedAt: string;
    updatedAtLocal: LocalTime;
    updatedBy: string;
    updatedByDevice: string;
    createdByName: UserName;
    updatedByName: UserName;
}

export interface InputCreateDataClarificationForm {
    patientId: string;
    studyInstanceId: string;
    studyId: string;
    siteId: string;
    armId: string;
    eventGroupId: string;
    eventId: string;
    activityGroupId: string;
    activityId: string;
    eventDayOffset: number;
    eventTime: string;
    taskInstanceId: string;
    reasonId: string;
    reasonOther: string;
    description: string;
}

//============================================
// ActivityInstnace

export interface ActivityInstanceAuditHistory {
    pk: string;
    sk: string;
    tn: string;
    version: number;
    activityRevision: number;
    eventName: string;
    updatedAt: string;
    updatedBy: string;
    updatedByName: UserName;
    dataDiff: string;
    answers: AuditDataHistory[];
}
export interface AuditDataHistory {
    pk: string;
    sk: string;
    tn: string;
    version: number;
    eventName: string;
    updatedAt: string;
    updatedBy: string;
    updatedByName: UserName;
    dataDiff: string;
}

export interface CertificatesStatusPart {
    backgroundJobStartedAt: Date;
    backgroundJobFinishedAt: Date;
    backgroundJobErrored: boolean;
    totalUsersToBeExported: number;
    totalUsersAlreadyExported: number;
    presignedUrl: string;
}
export interface GetCertificatesForStudy {
    backgroundJobStartedAt: Date;
    backgroundJobFinishedAt: Date;
    backgroundJobErrored: boolean;
    totalUsersToBeExported: number;
    parts: [CertificatesStatusPart];
}

export enum ServerErrorTypes {
    DbNotFound = 'DbNotFound',
    InternalServerError = 'InternalServerError',
    Error = 'Error',
    Unauthorized = 'Unauthorized',
    UserBlocked = 'UserBlocked',
    IncorrectSignature = 'IncorrectSignature',
    UnresolvedPreviousConflict = 'UnresolvedPreviousConflict',
    InvalidInput = 'InvalidInput',
    VersionConflict = 'VersionConflict',
    DuplicitEntry = 'DuplicitEntry',
}

export enum FileType {
    ALL_FILES = 'ALL_FILES',
    AUDIO = 'AUDIO',
    IMAGE = 'IMAGE',
    LINK = 'LINK',
    VIODEO = 'VIODEO',
    WORD = 'WORD',
    PDF = 'PDF',
    DOCUMENTS = 'DOCUMENTS',
}

export interface RFC {
    field?: string;
    reasonId?: string;
    reasonText?: string;
}

export interface FilterDocumentList {
    itemLabel: string;
    itemValue: string;
    isChecked: boolean;
}

export interface ConflictError {
    code?: string;
    message?: string;
    additionalInfo?: string;
}

export interface ConflictData {
    pk: string;
    sk: string;
    version: number;
    isDeleted: number;
    userId?: string;
    signature?: string;
    expectedVersion?: number;
    endponumber?: string;
    errors?: ConflictError[];
    arguments?: string;
    schema?: string;
    createdAt?: string;
    createdAtLocal?: LocalTime;
    createdBy?: string;
    createdByDevice?: string;
    updatedAt?: string;
    updatedAtLocal?: LocalTime;
    updatedBy?: string;
    updatedByDevice?: string;
    updatedWithRFC?: RFC[];
    createdByName?: UserName;
    updatedByName?: UserName;
}

export enum UserTabEnum {
    Templates = 0,
    History = 1,
}

export enum StudyModalityEnum {
    MOBILE = 'MOBILE',
    WEB = 'WEB',
}

export type TranscribableEnum = 'REQUIRED' | 'OPTIONAL' | 'FORBIDDEN';

export enum CanMoveSeriesWithVisitEnum {
    REQUIRED = 'REQUIRED',
    OPTIONAL = 'OPTIONAL',
    FORBIDDEN = 'FORBIDDEN',
}

export enum IdentityProvider {
    Cognito = 'Cognito',
    Okta = 'Okta',
}

export enum IdentityProviderPartner {
    JJ = 'Janssen',
    CI = 'Clinical Ink',
}

export enum DeviceStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    PENDING = 'PENDING',
    OTHER = 'OTHER',
}

export enum DeviceSubStatusEnum {
    PENDING_DUPLICATE = 'PENDING_DUPLICATE',
    PENDING_NEW = 'PENDING_NEW',
    PENDING_ALLINACTIVE = 'PENDING_ALLINACTIVE',
    INACTIVE_DECOMMISIONED = 'INACTIVE_DECOMMISIONED',
    INACTIVE_DAMAGED = 'INACTIVE_DAMAGED',
}

export enum DeviceTypeEnum {
    PROVISIONED = 'PROVISIONED',
    BYOD = 'BYOD',
}

export enum DeviceDeviceTypeEnum {
    TABLET = 'TABLET',
    MOBILE = 'MOBILE',
}

export interface Device {
    pk: string;
    sk: string;
    version: number;
    updatedAt: string;
    updatedBy: string;
    status: DeviceStatusEnum;
    subStatus: string;
    salesforceId: number;
    imei: number;
    type: DeviceTypeEnum;
    deviceOS: string;
    deviceType: DeviceDeviceTypeEnum;
    deviceOSVersion: string;
    deviceModel: string;
    deviceManufacturer: string;
    isDeleted: number;
    updatedByName: {
        firstName: string;
        lastName: string;
        username: string;
    };
    serialNumber: string;
    globalSiteName: string;
    studyName: string;
    siteId: string;
    studyId: string;
    listOfStudies: {
        studyId: string;
        studyName: string;
    }[];
    listOfSites: {
        siteId: string;
        globalSiteName: string;
    }[];
    isAssigned: boolean;
}

export enum PatientUniqueIds {
    PRESCREENING = 'PRESCREENING',
    SCREENING = 'SCREENING',
    RANDOMIZATION = 'RANDOMIZATION',
}

export enum StudyActivityStatusEnum {
    BUILD = 'BUILD',
    PENDING = 'PENDING',
    LIVE = 'LIVE',
}

export interface ActivityFlags {
    hasHomeActivities?: boolean;
    hasSiteActivities?: boolean;
}

export enum StudyDefaultPatientStatusEnum {
    Preconsent = 'Preconsent',
    Reconsent = 'Reconsent',
    DidNotConsent = 'DidNotConsent',
    Consented = 'Consented',
    Screened = 'Screened',
    ScreenFailed = 'ScreenFailed',
    Rescreened = 'Rescreened',
    Enrolled = 'Enrolled',
    Randomized = 'Randomized',
    Completed = 'Completed',
    Discontinued = 'Discontinued',
    Withdrew = 'Withdrew',
}

export interface PatientDetails {
    //Published Study Details
    pk: string;
    sk: string;
    status: string;
    versionStr: string;
    versionStatus: string;
    //Paitent Specific Details
    prescreeningIdFormat: string;
    screeningIdFormat: string;
    randomizationIdFormat?: string;
    screeningIdFormatRegex: string;
    prescreeningIdFormatRegex: string;
    randomizationIdFormatRegex?: string;
    collectPrescreeningId: boolean;
    collectScreeningId: boolean;
    collectRandomizationId?: boolean;
    randomizationFeature?: boolean;
    collectFirstName: string;
    collectLastName: string;
    collectInitials: string;
    collectSex: string;
    collectDateOfBirth: string;
    dateOfBirthFormat: string;
    collectPreferredLanguage: string;
    allowedDeviceType: string[];
    defaultPatientStatuses?: StudyDefaultPatientStatusEnum[];
    prescreeningIdAlias?: string;
    screeningIdAlias?: string;
    randomizationIdAlias?: string;
    //caregiver feature
    maxNumberOfActiveCaregivers?: number;
    caregiverFeature?: boolean;
    caregiverRequired?: boolean;
    caregiverLAR?: boolean;
    caregiverLARRequired?: boolean;
    caregiverAllowedDeviceType?: string[];
    //Status Aliases
    defaultPatientStatusScreenedAlias?: string;
    defaultPatientStatusEnrolledAlias?: string;
    defaultPatientStatusCompletedAlias?: string;
    defaultPatientStatusRandomizedAlias?: string;
    defaultPatientStatusScreenFailedAlias?: string;
    defaultPatientStatusDidNotConsentAlias?: string;
    defaultPatientStatusDiscontinuedAlias?: string;
    defaultPatientStatusRescreenedAlias?: string;
    defaultPatientStatusPreconsentAlias?: string;
    defaultPatientStatusReconsentAlias?: string;
    defaultPatientStatusConsentedAlias?: string;
    startDate?: string;
    goLiveDate?: string;
    econsentFeature?: boolean;
    patientIDsToDisplay?: PatientUniqueIds[];
    canTranscribeTask?: boolean;
    canTranscribeActivity?: boolean;
    studyModality: string[];
    eConsentStaffControl?: boolean;
    triggerReconsent?: boolean;
    activities?: ActivityFlags[];
    queryFeature?: boolean;
    signaturesDisabled?: boolean;
    activityReviewsFeature?: boolean;
    mdsPlatforms?: MdsPlatformEnum[];
}

export interface PendoGuide {
    pk: string;
    guideName: string;
    pendoGuideStudyRole?: { associatedRoleIds: string[]; version?: number; pk?: string };
    version?: number;
}
